import React from "react";
import "../../assets/css/video.scoped.css"; // Main CSS file is bootstrap.scoped.css
import { saveEmotion } from "../../utils/usersAPI";
import { getMessage } from "../emotion/getMessage";
import startDancing from "../../assets/img/btn_StartVideo.png";
import pauseDancing from "../../assets/img/btn_StopVideo.png";
import homeButton from "../../assets/img/btn_home_Off3.svg";
import logOutButton from "../../assets/img/btn_LogOut_Off3.svg";
import completeDanceButton from "../../assets/img/btn_complete_dance_1.png";
import prevButton from "../../assets/img/btn_arrow_left.png";
import nextButton from "../../assets/img/btn_arrow_right.png";
import btn_profile from "../../assets/img/btn_profile.svg";
import connectSound from "../../assets/audio/OnConnectionSound.wav";
import helloSound from "../../assets/audio/HelloAudio.wav";

import { Link, Navigate } from "react-router-dom";
import YouTubePlayer from "./YouTubePlayer";
import EmotionWidget from "../emotion/EmotionWidget";
import * as usersAPI from "../../utils/usersAPI";

import Carousel from "react-bootstrap/Carousel";

// import VIDEO_LENGTHS from "../../utils/videoLengths";

import videos from "../../assets/video/videos";
import playlists from "../../assets/video/playlists";
import "../../assets/css/bootstrap.scoped.css";

import { io } from "socket.io-client";
import { FinishedDancingModal } from "../emotion/FinishedDancingModal";
import EmojiMessage from "../emotion/EmojiMessage";
import MediaContainer from "./MediaContainer";

let token = window.localStorage.getItem("token");
const headers = {
  Accept: "application/json, text/plain, */*",
  "Content-Type": "application/json",
  Authorization: token,
};

export default class VideoContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayEmotion: false,
      displayFinishedDancing: false,
      displayEmojiMessage: false,
      username: "",
      roomName: window.location.href.split("=")[1].toLowerCase(),
      twilioRoom: null,
      token: null,
      sessionId: "",
      videoLogInfo: {
        videoId: "",
        videoSpeed: "",
        videoName: "",
      },
      filteredVideos: [],
      videoPlayers: [],
      videoEnergy: window.localStorage.getItem("speed")
        ? window.localStorage.getItem("speed").toString()
        : "",
      // disabled for now - need playlists in order to match video times with the buttons
      videoDuration:
        window.localStorage.getItem("time") &&
        window.localStorage.getItem("time") === 0
          ? ""
          : window.localStorage.getItem("time").toString(),
      playing: false,
      videoIsStarting: false,
      videoStarted: false,
      currentVideoIndex: 0,
      topVideoHeight: 0,
      topVideoWidth: 0,
      topVideoRef: React.createRef(),
      // Media Control variables
      isCameraVideoPopUp: false,
      isFullScreen: false,
      isMicOn: true,
      isCameraOn: true,
      isCameraMirrored: true,
    };

    this.afterDanceEmotion = 0;
    this.returnToLobby = this.returnToLobby.bind(this);
    this.currentVideoTimer = 0;
    this.currentVideoTime = 0;
    this.videoRefsArray = [];
    var CAMERA_POP_UP_WIDTH = 200;
    //this.videoRef = React.createRef();
    this.socket = io(process.env.REACT_APP_SOCKET_URL, {
      // this.socket = io(`http://184.169.191.154:8000/`, {
      // this.socket = io("http://websocket.buddydance.com:8000/", {
      withCredentials: true,
      extraHeaders: {
        "my-custom-header": this.state.roomName.toLowerCase(),
      },
    });
    // console.log("videoContainer socket.io", this.socket);
    for (const i of playlists) {
      this.videoRefsArray.push(React.createRef());
    }
  }

  openFullscreen = () => {
    this.setState({ isFullScreen: true });
    var elem = document.documentElement
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  }
  closeFullscreen = () => {
    this.setState({ isFullScreen: false });
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
  }

  handleMouseDrag = () => {
    const bottomDiv = document.querySelector('.bottom-container-div');
    bottomDiv.addEventListener("mousemove", this.OnDrag);
    document.addEventListener("mouseup", () => {
      this.removeMouseMoveEvent();
    });
  }
  removeMouseMoveEvent = (params) => {
    const bottomDiv = document.querySelector('.bottom-container-div');
    if (bottomDiv !== null) {
      bottomDiv.removeEventListener("mousemove", this.OnDrag);
    }
  }

  // Function that get current position of the bottom div which holds the camera videos and trasform the div to the mouse movement
  OnDrag({ movementX, movementY }) {
    const bottomDiv = document.querySelector('.bottom-container-div');
    let maxX = window.innerWidth;
    let maxY = window.innerHeight;
    let getStyle = window.getComputedStyle(bottomDiv);
    let left = parseFloat(getStyle.left);
    let top = parseFloat(getStyle.top);
    let width = parseFloat(getStyle.width)
    let height = parseFloat(getStyle.height)
    let bottomBarHeight = 60

    console.log(`(${left}, ${top})`);
    // Move the pop-up if inside the screen
    if (left >= 0 && left <= maxX - width) {
      bottomDiv.style.right = "";
      bottomDiv.style.left = `${left + movementX}px`;
      bottomDiv.style.top = `${top + movementY}px`;
    }
    if (left < 0) {
      // console.log('LEFT BORDER');
      bottomDiv.style.right = "";
      bottomDiv.style.left = `0px`;
    }
    if (left > maxX - width) {
      // console.log('RIGHT BORDER');
      bottomDiv.style.right = "";
      bottomDiv.style.left = `${maxX-width-2}px`;
    }
    if (top < 60) {
      // console.log('TOP BORDER');
      bottomDiv.style.right = "";
      bottomDiv.style.top = `${60}px`;
    }
    if (top >= maxY - height - bottomBarHeight) {
      // console.log('BOTTOM BORDER');
      bottomDiv.style.right = "";
      bottomDiv.style.top = `${maxY - height - bottomBarHeight - 2}px`;
    }
  }

  joinRoom = async () => {
    this.setState({ twilioRoom: this.state.roomName.toLowerCase() });
  };

  returnToLobby() {
    this.setState({ twilioRoom: null });
  }

  logVideoEvent = async (event, videoAction) => {
    //console.log("videoContainer props: ", this.props);
    //console.log("Log Video event fired in VideoContainer component: "),
    // videoAction,
    // this.state.filteredVideos[this.state.currentVideoIndex].id,
    // this.state.filteredVideos[this.state.currentVideoIndex].speed,
    // this.state.filteredVideos[this.state.currentVideoIndex].title);
    let videoSpeedToLog = this.state.filteredVideos[
      this.state.currentVideoIndex
    ].speed;
    let videoSpeedConvertedToWord;

    if (videoSpeedToLog === "1") {
      videoSpeedConvertedToWord = "low";
    } else if (videoSpeedToLog === "2") {
      videoSpeedConvertedToWord = "medium";
    } else if (videoSpeedToLog === "3") {
      videoSpeedConvertedToWord = "high";
    } else {
      videoSpeedConvertedToWord = "";
    }

    const values = {
      user: {
        username: this.state.username,
      },
      videoId: this.state.filteredVideos[this.state.currentVideoIndex].id,
      videoSpeed: videoSpeedConvertedToWord,
      videoTitle: this.state.filteredVideos[this.state.currentVideoIndex].title,
      event: {
        action: videoAction,
        timeElapsed: this.currentVideoTime,
      },
      sessionId: "need to add",
    };

    const result = await usersAPI.logVideoEvent(values);
    //("Log video event: ", result);
  };

  handleLogVideoStart = (event) => {
    //console.log("Video Started: ", event);
    this.currentVideoTime = 0;
    this.logVideoEvent(event, "Video Started");
    // need to code log event to database via service
  };

  handleVideoEnded = (event) => {
    // console.log(
    //   "Video Ended: ",
    //   event,
    //   this.state.currentVideoIndex,
    //   this.state.videoPlayers.length
    // );
    this.setState({ videoStarted: false });
    if (this.state.currentVideoIndex < this.state.videoPlayers.length - 1) {
      this.logVideoEvent(event, "Video Finished");
      this.setState({ currentVideoIndex: this.state.currentVideoIndex + 1 });
      this.setState({ playing: false }, () => this.handlePlayPause());
      //this.handleSlideChanged(this.state.currentVideoIndex+1);
    } else {
      this.logVideoEvent(event, "Video and Playlist Finished");
      this.setState({ playing: false });
      this.setState({ displayFinishedDancing: true });
    }
  };

  handleModalResponse = (response) => {
    this.setState({ displayFinishedDancing: false });
    if (response === "yes") {
      this.setState({ displayEmotion: true });
    } else {
    }
  };

  handleEmotion = async (emotionId) => {
    const emotion = getMessage(emotionId);
    const result = await saveEmotion({
      username: this.state.username,
      emotion: emotion?.emotion,
      afterDance: true,
    });
    //console.log(
    //   "Emotion after dance saving result is: ",
    //   result,
    //   this.state.username,
    //   emotion?.emotion
    // );
    this.afterDanceEmotion = emotion?.emotion || 0;
    this.setState({ displayEmotion: false });
    this.setState({ displayEmojiMessage: true });
  };

  handleEmojiClick = () => {
    this.setState({ displayEmojiMessage: false });
  };

  handleSlideChanged = async (event) => {
    //console.log("slide changed event: ", event);
    // disabled for now - used to test
    // this.setState({ displayFinishedDancing: true });
    this.setState({ videoStarted: false });
    if (this.state.playing) {
      this.logVideoEvent(event, "Video Changed While Playing");
      await this.handlePlayPause();
    }
    if (this.currentVideoTime > 0) {
      this.logVideoEvent(event, "Video Changed While Paused");
      this.currentVideoTime = 0;
    }
    this.setState({ currentVideoIndex: event });
    this.socket.emit("change-video", {
      room: this.state.roomName,
      videoEnergy: this.state.videoEnergy,
      videoDuration: this.state.videoDuration,
      currentVideoIndex: event,
    });
    //console.log("slide changed refs: ", this.videoRefs);
  };

  videoListing = (isReceiving = false) => {
    //console.log(this.state.videoEnergy, this.state.videoDuration);
    // console.log(
    //   "Local Storage: ",
    //   window.localStorage,
    //   this.state.videoEnergy,
    //   this.state.videoDuration
    // );
    //let filteredVideos = playlists;
    let filteredPlaylists;
    const filteredVideos = [];
    //let filteredVideos = videos.filter(video)
    //console.log(
    //  "filteredPlaylists: ",
    //  filteredPlaylists,
    //  this.state.videoEnergy,
    //  this.state.videoDuration
    //  );

    if (this.state.videoEnergy && this.state.videoDuration) {
      filteredPlaylists = playlists.filter((playlist) => {
        //console.log(this.state.videoEnergy, this.state.videoDuration);
        return (
          playlist.speed === this.state.videoEnergy &&
          playlist.time === this.state.videoDuration
          // Number.parseInt(video.time) >=
          //   VIDEO_LENGTHS[this.state.videoDuration].start &&
          // Number.parseInt(video.time) <=
          //   VIDEO_LENGTHS[this.state.videoDuration].end
        );
      });
    } else if (this.state.videoEnergy) {
      //console.log("----- Hit videoEnergy filter");
      filteredPlaylists = playlists.filter(
        (playlist) => playlist.speed === this.state.videoEnergy
      );
    } else if (this.state.videoDuration) {
      filteredPlaylists = playlists.filter((playlist) => {
        return (
          playlist.time === this.state.videoDuration
          // Number.parseInt(video.time) >=
          //   VIDEO_LENGTHS[this.state.videoDuration].start &&
          // Number.parseInt(video.time) <=
          //   VIDEO_LENGTHS[this.state.videoDuration].end
        );
      });
    }

    filteredPlaylists.forEach((playlist) => {
      playlist.videos.forEach((videoToMatch) => {
        filteredVideos.push(videos.find((video) => video.id === videoToMatch));
      });
    });

    //console.log("filteredVideos in videoListing: ", filteredVideos);
    this.setState({ filteredVideos: filteredVideos, currentVideoIndex: 0 });
    const videoPlayers = filteredVideos.map((video, idx) => {
      //this.videoRefs.push(React.CreateRef);
      return (
        <div id="vimeoPlayerContainer" key={`video_${idx}`}>
          <YouTubePlayer
            id={idx}
            currentId={this.state.currentVideoIndex}
            videoId={video.videoId}
            // vimeoId={video.vimeoId}
            youTubeUrl={video.url}
            ref={this.videoRefsArray[idx]}
            videoHeight={this.state.topVideoHeight}
            videoWidth={this.state.topVideoWidth}
            goToNextVideo={this.handleVideoEnded}
            logVideoStart={this.handleLogVideoStart}
            isCameraVideoPopUp={this.state.isCameraVideoPopUp}
          />
        </div>
        // <img key={video.id} id={video.id} />
      );
    });
    //console.log("videoPlayers map: ", videoPlayers);
    //console.log("videoRefs: ", this.videoRefs);
    this.setState({ videoPlayers: videoPlayers });
    if (!isReceiving) {
      this.socket.emit("change-video", {
        room: this.state.roomName.toLowerCase(),
        videoEnergy: this.state.videoEnergy,
        videoDuration: this.state.videoDuration,
        currentVideoIndex: this.state.currentVideoIndex,
      });
    }
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleVideoEnergy = (event) => {
    console.log(`ENERGY LEVEL: ${event.target.id}`);
    event.preventDefault();
    this.setState({ videoStarted: false }, () => {
      if (this.state.playing) {
        this.handlePlayPause();
      }
      this.setState({ videoEnergy: event.target.id }, () =>
        this.videoListing()
      );
    });
  };

  handleVideoDuration = (event) => {
    this.setState({ videoStarted: false }, () => {
      if (this.state.playing) {
        this.handlePlayPause();
      }
      this.setState({ videoDuration: event.target.id }, () =>
        this.videoListing()
      );
    });
  };

  setDefaultDuration = (event, duration="30") => {
    this.setState({ videoStarted: false }, () => {
      if (this.state.playing) {
        this.handlePlayPause();
      }
      this.setState({ videoDuration: duration }, () =>
        this.videoListing()
      );
    });
    console.log("DEFAULT DURATION SET");
    
  };

  handleEndDanceClick = () => {
    this.setState({ displayFinishedDancing: true });
    if (this.state.playing) this.handlePlayPause();
  };

  handlePlayPause = (event) => {
    if (this.state.playing) {
      this.removeControlBarsAnimation();
    } else {
      this.addControlBarsAnim();
    }
    this.expandImages();
    if (this.state.playing && this.state.videoStarted) {
      // Pausing video
      clearInterval(this.currentVideoTimer);
      //console.log("Video Paused with this time elapsed: ", this.state.videoId, this.currentVideoTime);
    } else if (this.state.playing && !this.state.videoStarted) {
      // changed to new video - stopping current one
      // console.log(
      //   "changed to new video - stopping current one - videoStarted: ",
      //   this.state.videoStarted,
      //   "videoPlaying: ",
      //   this.state.playing
      // );
      clearInterval(this.currentVideoTimer);
    } else {
      // starting video - either paused or new play

      this.setState({ videoIsStarting: true }, () => {
        // console.log(
        //   "starting video - videoIsStarting: ",
        //   this.state.videoIsStarting
        // );

        this.currentVideoTimer = setInterval(
          () => (this.currentVideoTime += 1),
          1000
        );

        setTimeout(
          () => {
            this.setState({ videoIsStarting: false });
          },
          this.state.videoIsStarting
            ? this.state.videoStarted
              ? 3 * 1000
              : 5 * 1000
            : 0
        );
        //console.log("Video started with this much time elapsed: ", this.currentVideoTime);
      });
    }

    this.setState({ playing: !this.state.playing }, () => {
      //console.log("HandlePlayPause current Id: ", this.state.currentVideoIndex);
      //console.log("HandlePlayPause playing: ", this.state.playing);
      //console.log("handlePlay ref array: ", this.videoRefsArray);
      //console.log("handleplay videoRef:", this.videoRef);
      this.videoRefsArray[this.state.currentVideoIndex].current.setPlaying(
        this.state.videoStarted
      );

      if (this.state.playing) {
        this.setState({ videoStarted: true });
      }

      if (event) {
        this.socket.emit("play-pause-video", {
          room: this.state.roomName.toLowerCase(),
          isVideoPlaying: this.state.playing,
        });
      }
    });
  };

  componentDidMount() {
    // See https://www.twilio.com/docs/video/tutorials/user-identity-access-tokens for information
    this.setState(
      {
        topVideoHeight: this.state.topVideoRef.current.clientHeight - 0,
        topVideoWidth: this.state.topVideoRef.current.clientHeight * 1.5625,
      },
      () => {
        //("topvideoheight: ", this.state.topVideoHeight, this.state.topVideoWidth)
        this.videoListing();
      }
    );

    let username = null;
    if (this.props.location && this.props.location.state) {
      username = this.props.location.state.username;
      this.setState({
        username: username,
      });
    } else {
      username = window.localStorage.getItem("username");
      this.setState({
        username: username,
      });
    }
    this.joinRoom();
    // if (!roomname || roomname === "") {
    //   fetch(`${api}/python/app`, { headers })
    // .then(res => res.json())
    // .then(res => console.log("==== Response: ", res));
    // }

    this.socket.on("connect", (data) => {
      const transport = this.socket.io.engine.transport.name; // in most cases, "polling"
      // console.log("socket connect transport: ", transport);
      // const audio = new Audio(helloSound);
      // audio.play();
    });

    this.socket.io.on("error", (error) => {
      console.log("socket error: ", error);
    });

    this.socket.on("reconnect", (attempt) => {
      // console.log("reconnect attempt #: ", attempt);
    });

    this.socket.io.engine.on("upgrade", () => {
      const upgradedTransport = this.socket.io.engine.transport.name; // in most cases, "websocket"
      // console.log("socket upgrade upgradedTransport: ", upgradedTransport);
    });

    this.socket.on("video-state", (data) => {
      if (this.state.playing !== data.isVideoPlaying) {
        this.handlePlayPause();
      }
    });
    this.socket.on("video-index", (data) => {
      this.setState({ videoStarted: false }, () => {
        if (this.state.playing) {
          this.handlePlayPause();
        }
        if (this.state.currentVideoIndex !== data.currentVideoIndex) {
          this.setState(
            { currentVideoIndex: data.currentVideoIndex },
            () => {}
          );
        } else {
          this.setState(
            {
              videoEnergy: data.videoEnergy,
              videoDuration: data.videoDuration,
            },
            () => {
              this.videoListing(true);
            }
          );
        }
      });
      // if (this.state.playing) {
      //   this.handlePlayPause();
      // }
    });

    // Open Main Fullscreen Mode
    setTimeout(() =>{
      this.toggleCameraVideoPopUp();
      this.resizeCameraVideos();
    }, 150)

    // Handle On Mouse Move Control Bar Animations
    document.addEventListener('mousemove', () => {
      this.removeControlBarsAnimation();
      setTimeout(() => {
        this.addControlBarsAnim();
      }, 2500);
    });

    window.addEventListener('resize', () => {
      this.resizeCameraVideos();
    });

    setTimeout(() => {
      this.setDefaultDuration();  
    }, 500);

    this.expandImages();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isCameraVideoPopUp !== this.state.isCameraVideoPopUp) {
      const updatedVideoPlayers = this.state.filteredVideos.map(
        (video, idx) => {
          //this.videoRefs.push(React.CreateRef);
          return (
            <div id="vimeoPlayerContainer" key={`video_${idx}`}>
              <YouTubePlayer
                id={idx}
                currentId={this.state.currentVideoIndex}
                videoId={video.videoId}
                // vimeoId={video.vimeoId}
                youTubeUrl={video.url}
                ref={this.videoRefsArray[idx]}
                videoHeight={this.state.topVideoHeight}
                videoWidth={this.state.topVideoWidth}
                goToNextVideo={this.handleVideoEnded}
                logVideoStart={this.handleLogVideoStart}
                isCameraVideoPopUp={this.state.isCameraVideoPopUp}
              />
            </div>
            // <img key={video.id} id={video.id} />
          );
        }
      );
      this.setState({ videoPlayers: updatedVideoPlayers });
    }
  }

  componentWillUnmount() {
    //this.handleSlideChanged();
    this.socket.close();
    this.handleLeave();
  }

  handleLeave = (event) => {
    this.setState({ twilioRoom: null });
  };

  handleLogout = (event) => {
    window.localStorage.clear();
    //TODO: Complete for logout document.cookie(access_token:'');
  };

  resizeCameraVideos = () => {
    // Camera Videos
    const cameraVideoContainer = document.querySelector('.camera-video-container');
    const localVideo = document.querySelector(".local-video");
    const remoteVideo = document.querySelector(".remote-video");

    if (cameraVideoContainer === null) {
      return;
    }
    if (localVideo === null) {
      return;
    }
    if (remoteVideo === null) {
      return;
    }

    if (!this.state.isFullScreen) {
      if (window.innerWidth <= 700) {
        localVideo.style.width = '100vw';
        remoteVideo.style.width = '100vw';
        localVideo.style.height = '25vh';
        remoteVideo.style.height = '25vh';
        cameraVideoContainer.style.flexDirection = 'column';
      } else {
        localVideo.style.width = '50vw';
        remoteVideo.style.width = '50vw';
        localVideo.style.height = '50vh';
        remoteVideo.style.height = '50vh';
        cameraVideoContainer.style.flexDirection = 'row';
      }
      return;
    }

    if (localVideo !== null && remoteVideo !== null) {
      if (window.innerHeight <= 500) {
        localVideo.style.width = 'auto';
        remoteVideo.style.width = 'auto';
        localVideo.style.height = window.innerHeight/4+'px';
        remoteVideo.style.height = window.innerHeight/4+'px';
  
      }
      if (window.innerWidth <= 500) {
        localVideo.style.width = window.innerWidth/3+'px';
        remoteVideo.style.width = window.innerWidth/3+'px';
        localVideo.style.height = 'auto';
        remoteVideo.style.height = 'auto';
  
      }
      if (window.innerWidth > 500 && window.innerHeight > 500) {
        localVideo.style.width = '230px';
        remoteVideo.style.width = '230px';
        localVideo.style.height = '178.5px';
        remoteVideo.style.height = '178.5px';
      } 
    }
  }

  toggleCameraRotation = () => {
    const video = document.getElementsByClassName("local-video");
    let factor = this.state.isCameraMirrored ? "1" : "-1";
    video[0].style.transform = "scaleX(" + factor + ")";
    this.setState({isCameraMirrored: !this.state.isCameraMirrored})
  }

  toggleVideoState = () => {
    this.setState({ isCameraOn: !this.state.isCameraOn })
  }

  toggleAudioState = () => {
    this.setState({ isMicOn: !this.state.isMicOn })
  }

  addControlBarsAnim = () => {
    const NavBtnDiv = document.querySelector(".nav-btn-div");
    const ControlButtonDiv = document.querySelector(".control-button-div");

    if (this.state.playing) {
      if (NavBtnDiv !== null && ControlButtonDiv !== null) {
        NavBtnDiv.style.transition = 'opacity 2.5s';
        ControlButtonDiv.style.transition = 'opacity 2.5s';
        NavBtnDiv.style.opacity = 0;
        ControlButtonDiv.style.opacity = 0;
      }
    } else {
      if (NavBtnDiv !== null && ControlButtonDiv !== null) {
        NavBtnDiv.style.opacity = 1;
        ControlButtonDiv.style.opacity = 1;
      }
    }
  }

  removeControlBarsAnimation = () => {
    const NavBtnDiv = document.querySelector(".nav-btn-div");
    const ControlButtonDiv = document.querySelector(".control-button-div");
    // RESET
    if (NavBtnDiv !== null && ControlButtonDiv !== null && NavBtnDiv !== undefined && ControlButtonDiv !== undefined) {
      NavBtnDiv.style = '';
      ControlButtonDiv.style = '';
    }
  }

  // Get all components from the DOM tree and change their styles to fit a full screen mode 
  toggleCameraVideoPopUp = (event) => {
    // Images
    const screenImgs = document.querySelectorAll("#screen-img");
    const startImgs = document.querySelectorAll("#start-img");
    // Next Video Btns
    const next = document.querySelector('.carousel-control-next');
    const prev = document.querySelector('.carousel-control-prev');
    if (next !== null) {
      next.style.display = 'none';
    }
    if (prev !== null) {
      prev.style.display = 'none'; 
    }
    // Dance Videos
    const videoCarousel = document.getElementById("videoCarousel");
    const carouselInner = videoCarousel.querySelector(".carousel-inner");
    const carouselItems = document.querySelectorAll(".carousel-item");
    const vimeoPlayerContainers = document.querySelectorAll("#vimeoPlayerContainer");
    const reactPlayers = document.querySelectorAll("#react-player");
    // Navbar menu
    const navBtnDiv = document.querySelector(".nav-btn-div");
    // Layout main divs
    const topDiv = document.querySelector(".top-container-div");
    const bottomDiv = document.querySelector(".bottom-container-div");
    const buddyVideoDiv = document.querySelector("#buddy-video-div");
    // Camera Videos
    const cameraVideoContainer = document.querySelector('.camera-video-container');
    const localVideo = document.querySelector(".local-video");
    const remoteVideo = document.querySelector(".remote-video");
    // Controls divs
    const startVideoDiv1 = document.querySelector("#start-video-div");
    const startVideoDiv2 = document.querySelector("#start-video-div2");
    const controlButtonDiv = document.querySelector(".control-button-div");
    const cameraControls = document.getElementById("camera-controls");
    // If camera video is not pop up ajust style properties to the new layout else reset all styles to the previous.
    if (!this.state.isCameraVideoPopUp) {
      this.openFullscreen();
      if (navBtnDiv !== null) {
        navBtnDiv.style.background = 'rgb(33, 33, 33, 1)';
        navBtnDiv.style.alignItems = 'center';
      }
      if (startVideoDiv1 !== null) {
        startVideoDiv1.style.display = "none";
        startVideoDiv2.style.display = "flex";
      }
      if (videoCarousel !== null) {
        videoCarousel.style.height = "100vh";
        videoCarousel.style.width = "100vw";
        videoCarousel.style.backgroundColor = "#000";
      }
      if (carouselInner !== null) {
        carouselInner.style.height = "100vh";
        carouselInner.style.width = "100vw";
      }
      if (carouselInner.children !== null) {
        for (let i = 0; i < carouselInner.children.length; i++) {
          carouselInner.children[i].style.height = "100%";
          carouselInner.children[i].style.width = "100%";
        }
      }
      if (carouselItems !== null) {
        // console.log(carouselItems);
        for (let i = 0; i < carouselItems.length; i++) {
          carouselItems[i].style.width = '100%';
          carouselItems[i].style.height = '100%';
        }
      }
      if (vimeoPlayerContainers !== null) {
        for (let i = 0; i < vimeoPlayerContainers.length; i++) {
          vimeoPlayerContainers[i].style = "";
          vimeoPlayerContainers[i].style.width = "100%";
          vimeoPlayerContainers[i].style.height = "100%";
        }
      }
      if (reactPlayers !== null) {
        for (let i = 0; i < reactPlayers.length; i++) {
          reactPlayers[i].style.height = "100%";
          reactPlayers[i].style.width = "100%";
        }
      }
      if (screenImgs !== null) {
        for (let i = 0; i < screenImgs.length; i++) {
          screenImgs[i].style.width = "100%";
          screenImgs[i].style.height = "100%";
        }
      }
      if (startImgs !== null) {
        for (let i = 0; i < startImgs.length; i++) {
          startImgs[i].style.width = "300px";
        }
      }
      if (cameraVideoContainer !== null) {
        cameraVideoContainer.style.flexDirection = 'column';
      }
      if (localVideo !== null) {
        localVideo.style.width = "230px";
        localVideo.style.height = "178.5PX";
      }
      if (remoteVideo !== null) {
        remoteVideo.style.width = "230px";
        remoteVideo.style.height = "178.5px";
      }
      if (cameraControls !== null) {
        cameraControls.style.display = "none";
      }
      if (controlButtonDiv !== null) {
        controlButtonDiv.style.position = "absolute";
        controlButtonDiv.style.bottom = "-2px";
        controlButtonDiv.style.backgroundColor = "#212121";
        controlButtonDiv.style.height = "60px";
      }
      if (bottomDiv !== null) {
        let bottomDivHeight = bottomDiv.style.height;
        bottomDiv.style.position = "absolute";
        bottomDiv.style.height = "fit-content";
        bottomDiv.style.right = "0px";
        bottomDiv.style.top = '60px'; //window.innerHeight/2 - bottomDivHeight*0.5  + 'px'
        bottomDiv.style.cursor = "grab";
        bottomDiv.style.zIndex = 1000;
      }
      if (buddyVideoDiv !== null) {
        buddyVideoDiv.style.height = "100%";
        buddyVideoDiv.style.width = "100%";
      }
      if (topDiv !== null) {
        topDiv.style.height = "100vh";
      }
      if (bottomDiv !== null) {
        // Add event listeners to video pop-up div so users can drag the window
        bottomDiv.addEventListener("mousedown", this.handleMouseDrag);
      }
      this.setState({ isCameraVideoPopUp: true });

    } else {
      // Top Div
      if (navBtnDiv !== null) {
        // navBtnDiv.style = "";
      }
      if (startVideoDiv1 !== null) {
        // startVideoDiv1.style = "";
      }
      if (startVideoDiv2 !== null) {
        // startVideoDiv2.style.display = "none";
      }
      if (videoCarousel !== null) {
        // videoCarousel.style = "";
      }
      if (controlButtonDiv !== null) {
        // controlButtonDiv.style = "";
        controlButtonDiv.style.position = "absolute";
        controlButtonDiv.style.bottom = "-2px";
        controlButtonDiv.style.backgroundColor = "#212121";
        controlButtonDiv.style.height = "60px";
      }
      if (topDiv !== null) {
        topDiv.style = "";
      }
      if (vimeoPlayerContainers !== null) {
        for (let i = 0; i < vimeoPlayerContainers.length; i++) {
          vimeoPlayerContainers[i].style = "";
        }
      }
      if (reactPlayers !== null) {
        for (let i = 0; i < reactPlayers.length; i++) {
          reactPlayers[i].style = "";
          reactPlayers[i].style = "width: 696.875px; height: 464px;";
        }
      }
      if (startImgs !== null) {
        for (let i = 0; i < startImgs.length; i++) {
          startImgs[i].style = "";
        }
      }

      // Bottom Div
      if (bottomDiv !== null) {
        bottomDiv.style = "";
      }
      if (buddyVideoDiv !== null) {
        buddyVideoDiv.style = "";
      }
      if (cameraVideoContainer !== null) {
        cameraVideoContainer.style.flexDirection = 'row';
      }
      if (localVideo !== null) {
        localVideo.style.width = "50vw";
        localVideo.style.height = "auto";
      }
      if (remoteVideo !== null) {
        remoteVideo.style.width = "50vw";
        remoteVideo.style.height = "auto";
      }
      if (carouselInner !== null) {
        carouselInner.style.width = "100vw";
        carouselInner.style.height = "auto";
      }
      if (cameraControls !== null) {
        cameraControls.style.display = "";
      }
      if (carouselInner !== null) {
        for (let i = 0; i < carouselInner.children.length; i++) {
          carouselInner.children[i].style = "";
        }
      }
      this.removeMouseMoveEvent();
      if (bottomDiv !== null) {
        bottomDiv.removeEventListener("mousedown", this.handleMouseDrag);
      }
      this.setState({ isCameraVideoPopUp: !this.state.isCameraVideoPopUp });
      this.closeFullscreen();
    }
  };

  expandImages = (event) => {
    const screenImgs = document.querySelectorAll("#screen-img");
    const startImgs = document.querySelectorAll("#start-img");
    
    if (this.state.isCameraVideoPopUp) {
      if (screenImgs !== null) {
        for (let i = 0; i < screenImgs.length; i++) {
          screenImgs[i].style = "";
          screenImgs[i].style.width = "100%";
          screenImgs[i].style.height = "100%";
        }
      }
      if (startImgs !== null) {
        for (let i = 0; i < startImgs.length; i++) {
          startImgs[i].style = "";
          startImgs[i].style.width = "250px";
        }
      }

    } else {
      if (screenImgs !== null) {
        for (let i = 0; i < screenImgs.length; i++) {
          screenImgs[i].style.width = "646.875px";
          screenImgs[i].style.height = "414px";
        }
      }
      if (startImgs !== null) {
        for (let i = 0; i < startImgs.length; i++) {
          startImgs[i].style = "";
        }
      }
    }
  };

  render() {

    return (
      <>
        {this.state.displayFinishedDancing ? (
          <FinishedDancingModal
            handleModalResponse={this.handleModalResponse}
          />
        ) : null}

        {this.state.displayEmotion ? (
          <EmotionWidget handleEmotion={this.handleEmotion} />
        ) : null}

        {this.state.displayEmojiMessage ? (
          <EmojiMessage
            heading="Great Job!!"
            emotion={this.afterDanceEmotion}
            handleClick={this.handleEmojiClick}
          />
        ) : null}
        {(this.state.displayFinishedDancing ||
          this.state.displayEmotion ||
          this.state.displayEmojiMessage) && (
          <div className="modal-bkg-overlay" />
        )}
        <div className="grid-container">

          <div className="top-container-div" ref={this.state.topVideoRef}>
            <div className="nav-btn-div">
              <div className="nav-btns">
                <Link className="link" to="/welcome">
                  {/* <img src={homeButton} alt="" /> */}
                  <div id="outlinedPinkBtn">
                    Home
                  </div>
                </Link>
                <Link to="/profile">
                  {/* <img id="profileBtn" src={btn_profile} alt="" /> */}
                  <div id="outlinedPinkBtn">
                    Profile
                  </div>
                </Link>
                <Link className="link" to="/">
                  {/* <img src={logOutButton} alt="" onClick={this.handleLogout} /> */}
                  <div id="outlinedPinkBtn">
                    Logout
                  </div>
                </Link>
              </div>
              <div
                id="pinkBtn"
                src={completeDanceButton}
                alt="Finish the dance"
                onClick={this.handleEndDanceClick}
                >
                Finish :D
              </div>
            </div>
            <Carousel
              id="videoCarousel"
              activeIndex={this.state.currentVideoIndex}
              onSelect={
                this.state.videoIsStarting ? null : this.handleSlideChanged
              }
              prevIcon={<img src={prevButton} alt="" />}
              nextIcon={<img src={nextButton} alt="" />}
              wrap={false}
              // onSlid={this.handleSlideChanged}
              interval={null}
              indicators={false}
            >
              {this.state.videoPlayers.map((player, idx) => {
                return <Carousel.Item key={idx}>{player}</Carousel.Item>;
              })}
            </Carousel>

          </div>

          <div className="bottom-container-div">

            <div id="start-video-div">

            </div>

            <div id="buddy-video-div">

              {this.state.twilioRoom ? (
                <MediaContainer
                  roomName={this.state.roomName.toLowerCase()}
                  returnToLobby={this.returnToLobby}
                  handleLogout={this.handleLogout}
                  socket={this.socket}
                  isMicOn={this.state.isMicOn}
                  isCameraOn={this.state.isCameraOn}
                  isFullScreen={this.state.isFullScreen}
                  username={this.state.username}
                />
              ) : null}

            </div>
          </div>

          <div className="control-button-div">
              <div
                id="start-video-div2"
                style={{ 
                  display: "none",
                  position: "absolute",
                  left: '50%',
                  right: '50%',
                  transform: 'translateX(-50%)',
                  alignItems: "center",
                  order: 1,
                  width: 'max-content',
                }}
              >
                <div
                  id="btnPlayDance"
                  onClick={
                    this.state.videoIsStarting ? null : this.handlePlayPause
                  }
                  alt="Start Button"
                  className={this.state.videoIsStarting ? "btn-disabled" : null}
                  >
                    {this.state.playing ? "Pause :)" : "Start  :)"}
                </div>
                <div
                  id="btnPlaySmall"
                  onClick={
                    this.state.videoIsStarting ? null : this.handlePlayPause
                  }
                  alt="Start Button"
                  className={this.state.videoIsStarting ? "btn-disabled" : null}
                  >
                  {this.state.playing ? //if is playing
                    <svg width="30px" height="30px" viewBox="-1 0 8 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                      <title>pause [#1006]</title>
                      <desc>Created with Sketch.</desc>
                      <defs>
                    </defs>
                      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd">
                          <g id="Dribbble-Light-Preview" transform="translate(-227.000000, -3765.000000)" fill="white">
                              <g id="icons" transform="translate(56.000000, 160.000000)">
                                  <path d="M172,3605 C171.448,3605 171,3605.448 171,3606 L171,3612 C171,3612.552 171.448,3613 172,3613 C172.552,3613 173,3612.552 173,3612 L173,3606 C173,3605.448 172.552,3605 172,3605 M177,3606 L177,3612 C177,3612.552 176.552,3613 176,3613 C175.448,3613 175,3612.552 175,3612 L175,3606 C175,3605.448 175.448,3605 176,3605 C176.552,3605 177,3605.448 177,3606" id="pause-[#1006]"></path>
                              </g>
                          </g>
                      </g>
                    </svg>
                   : //else
                    <svg width="30px" height="30px" viewBox="-1 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <title>play [#1000]</title>
                    <desc>Created with Sketch.</desc>
                    <defs>
                  </defs>
                      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <g id="Dribbble-Light-Preview" transform="translate(-65.000000, -3803.000000)" fill="white">
                              <g id="icons" transform="translate(56.000000, 160.000000)">
                                  <path d="M18.074,3650.7335 L12.308,3654.6315 C10.903,3655.5815 9,3654.5835 9,3652.8985 L9,3645.1015 C9,3643.4155 10.903,3642.4185 12.308,3643.3685 L18.074,3647.2665 C19.306,3648.0995 19.306,3649.9005 18.074,3650.7335" id="play-[#1000]"></path>
                              </g>
                          </g>
                      </g>
                  </svg>
                  }
                </div>
              </div>

              <div id="filter-div" style={{order: 0}}>

                <div className="my-tooltip">
                  <div className="tooltipText">{this.state.isMicOn ? 'Mute my audio': 'Unmute my audio'}</div>
                  <div className="dropbtn" onClick={this.toggleAudioState}>
                    {this.state.isMicOn ?
                        <>
                        <svg width="28px" height="28px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8 5C8 2.79086 9.79086 1 12 1C14.2091 1 16 2.79086 16 5V12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12V5Z"/>
                          <path d="M6.25 11.8438V12C6.25 13.525 6.8558 14.9875 7.93414 16.0659C9.01247 17.1442 10.475 17.75 12 17.75C13.525 17.75 14.9875 17.1442 16.0659 16.0659C17.1442 14.9875 17.75 13.525 17.75 12V11.8438C17.75 11.2915 18.1977 10.8438 18.75 10.8438H19.25C19.8023 10.8438 20.25 11.2915 20.25 11.8437V12C20.25 14.188 19.3808 16.2865 17.8336 17.8336C16.5842 19.0831 14.9753 19.8903 13.25 20.1548V22C13.25 22.5523 12.8023 23 12.25 23H11.75C11.1977 23 10.75 22.5523 10.75 22V20.1548C9.02471 19.8903 7.41579 19.0831 6.16637 17.8336C4.61919 16.2865 3.75 14.188 3.75 12V11.8438C3.75 11.2915 4.19772 10.8438 4.75 10.8438H5.25C5.80228 10.8438 6.25 11.2915 6.25 11.8438Z"/>
                        </svg>
                        </>
                        :
                        <>
                        <svg width="28px" height="28px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 1C13.6452 1 15.0585 1.99333 15.6728 3.41298L7.99997 11.0858V5C7.99997 2.79086 9.79083 1 12 1Z"/>
                          <path d="M6.24997 12C6.24997 12.2632 6.26801 12.5245 6.30342 12.7823L4.25194 14.8338C3.92295 13.9344 3.74997 12.9761 3.74997 12V11.8438C3.74997 11.2915 4.19769 10.8438 4.74997 10.8438H5.24997C5.80226 10.8438 6.24997 11.2915 6.24997 11.8438V12Z"/>
                          <path d="M7.3242 18.7971L3.76773 22.3535C3.3772 22.7441 2.74404 22.7441 2.35352 22.3535L1.64641 21.6464C1.25588 21.2559 1.25588 20.6227 1.64641 20.2322L20.2322 1.64644C20.6227 1.25591 21.2559 1.25591 21.6464 1.64644L22.3535 2.35354C22.744 2.74407 22.744 3.37723 22.3535 3.76776L16 10.1213V12C16 14.2091 14.2091 16 12 16C11.4457 16 10.9177 15.8873 10.4378 15.6835L9.13553 16.9857C9.99969 17.4822 10.986 17.75 12 17.75C13.525 17.75 14.9875 17.1442 16.0658 16.0659C17.1442 14.9875 17.75 13.525 17.75 12V11.8438C17.75 11.2915 18.1977 10.8438 18.75 10.8438H19.25C19.8023 10.8438 20.25 11.2915 20.25 11.8437V12C20.25 14.188 19.3808 16.2865 17.8336 17.8336C16.5842 19.0831 14.9753 19.8903 13.25 20.1548V23H10.75V20.1548C9.51944 19.9662 8.34812 19.5014 7.3242 18.7971Z"/>
                        </svg>
                        </>
                      }
                  </div>
                </div>

                <div className="my-tooltip">
                  <div className="tooltipText">{this.state.isCameraOn ? 'Turn off camera': 'Turn on camera'}</div>
                  <div className="dropbtn" onClick={this.toggleVideoState}>
                    {this.state.isCameraOn ?
                        <>
                          <svg width="28px" height="28px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2902 2C8.49207 2 6.89571 3.15059 6.3271 4.85641C6.26345 5.04736 6.10666 5.19256 5.91139 5.24138L5.10615 5.44269C3.28064 5.89906 2 7.53928 2 9.42097V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V9.42097C22 7.53928 20.7194 5.89906 18.8938 5.44268L18.0886 5.24138C17.8933 5.19256 17.7365 5.04736 17.6729 4.85641C17.1043 3.15059 15.5079 2 13.7098 2H10.2902ZM12 11C10.8954 11 10 11.8954 10 13C10 14.1046 10.8954 15 12 15C13.1046 15 14 14.1046 14 13C14 11.8954 13.1046 11 12 11ZM8 13C8 10.7909 9.79086 9 12 9C14.2091 9 16 10.7909 16 13C16 15.2091 14.2091 17 12 17C9.79086 17 8 15.2091 8 13Z"/>
                          </svg>
                        </>
                        :
                        <>
                          <svg width="28px" height="28px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.29289 2.29289C2.68342 1.90237 3.31658 1.90237 3.70711 2.29289L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L2.29289 3.70711C1.90237 3.31658 1.90237 2.68342 2.29289 2.29289Z"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.84401 5.25822L5.10615 5.44269C3.28064 5.89906 2 7.53928 2 9.42097V17C2 19.7614 4.23858 22 7 22H17C18.5298 22 19.8992 21.3129 20.8163 20.2306L15.5085 14.9227C14.8285 16.1608 13.5123 17 12 17C9.79086 17 8 15.2091 8 13C8 11.4877 8.83921 10.1715 10.0773 9.49154L5.84401 5.25822ZM15.9763 12.5621C15.7739 10.7028 14.2972 9.22615 12.4379 9.0237L7.32157 3.90736C7.14245 3.72824 7.12339 3.44094 7.30037 3.2597C8.06949 2.47205 9.13844 2 10.2902 2H13.7098C15.5079 2 17.1043 3.15059 17.6729 4.85641C17.7365 5.04736 17.8933 5.19256 18.0886 5.24138L18.8938 5.44268C20.7194 5.89906 22 7.53928 22 9.42097V17C22 17.1894 21.9895 17.3763 21.969 17.5603C21.9268 17.9385 21.4745 18.0603 21.2053 17.7911L15.9763 12.5621ZM11.6215 11.0358C10.6979 11.2127 10 12.0248 10 13C10 14.1046 10.8954 15 12 15C12.9752 15 13.7873 14.3021 13.9642 13.3785L11.6215 11.0358Z"/>
                          </svg>
                        </>
                      }
                  </div>
                </div>

                <div class="custom-dropdown">
                  <div class="dropdown-content">
                    <a href="">
                      <span
                        style={{ opacity: this.state.videoIsStarting ? ".3" : "1" }}
                        onClick={
                          this.state.videoIsStarting ? null : this.handleVideoEnergy
                        }
                        id="1"
                        className={
                          this.state.videoEnergy === "1"
                            ? "filterBtn filterActive"
                            : "filterBtn"
                        }
                      >
                        Easy Breezy
                      </span>
                    </a>
                    <a href="">
                      <span
                        style={{ opacity: this.state.videoIsStarting ? ".3" : "1" }}
                        onClick={
                          this.state.videoIsStarting ? null : this.handleVideoEnergy
                        }
                        ref={this.energyTwo}
                        id="2"
                        className={
                          this.state.videoEnergy === "2"
                            ? "filterBtn filterActive"
                            : "filterBtn"
                        }
                      >
                        Mellow Yellow
                      </span>
                    </a>
                    <a href="">
                      <span
                        style={{ opacity: this.state.videoIsStarting ? ".3" : "1" }}
                        onClick={
                          this.state.videoIsStarting ? null : this.handleVideoEnergy
                        }
                        id="3"
                        className={
                          this.state.videoEnergy === "3"
                            ? "filterBtn filterActive"
                            : "filterBtn"
                        }
                      >
                        Dance Machine
                      </span>
                    </a>
                  </div>
                  <button class="dropbtn">
                    <svg fill="white" width="28px" height="28px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M13,9h6L8,22l3-10H5L10,2h7Z"/></svg>
                    <span className="energy-level-text">Energy Level</span>
                  </button>
                </div>
                
              </div>
              <div />
              
              <div id="time-div" style={{order: 2}}>
                { /* MORE -> Flip Camera */ }
                <div className="dropdown my-tooltip">
                  <div className="tooltipText">Mirror my Camera</div>
                  <div className="dropbtn" onClick={this.toggleCameraRotation}>
                  <svg width="28px" height="28px" viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg" fill="#fff">
                    <path d="M 7.8086 50.3477 L 48.1914 50.3477 C 53.0663 50.3477 55.5508 47.9102 55.5508 43.0820 L 55.5508 18.5430 C 55.5508 13.7149 53.0663 11.3008 48.1914 11.3008 L 42.7069 11.3008 C 40.8789 11.3008 40.3164 10.9258 39.2617 9.7539 L 37.3633 7.6445 C 36.2149 6.3555 35.0196 5.6523 32.5820 5.6523 L 23.2539 5.6523 C 20.8398 5.6523 19.6445 6.3555 18.4727 7.6445 L 16.5742 9.7539 C 15.5430 10.9023 14.9571 11.3008 13.1289 11.3008 L 7.8086 11.3008 C 2.9336 11.3008 .4492 13.7149 .4492 18.5430 L .4492 43.0820 C .4492 47.9102 2.9336 50.3477 7.8086 50.3477 Z M 37.9258 37.5976 L 33.5196 31.7852 C 32.9102 30.9883 33.3086 29.9571 34.2461 29.9571 L 37.4102 29.9571 C 37.4102 24.2383 33.4961 20.2539 27.9883 20.2539 C 26.2539 20.2539 24.8476 20.6758 23.4180 21.4492 C 21.8242 22.2227 20.6055 21.3086 20.6055 20.1602 C 20.6055 19.5976 20.8633 18.9649 21.5430 18.5430 C 23.0430 17.6289 25.3867 16.8320 27.9883 16.8320 C 35.4649 16.8320 40.8789 22.2695 40.8789 29.9571 L 43.6679 29.9571 C 44.6288 29.9571 44.9807 30.9649 44.3711 31.7852 L 39.9414 37.5976 C 39.4492 38.2539 38.4649 38.2773 37.9258 37.5976 Z M 27.9883 42.5898 C 20.5117 42.5898 15.1211 36.8945 15.1211 29.4649 L 12.3320 29.4649 C 11.3476 29.4649 11.0196 28.4336 11.6289 27.6133 L 16.0586 21.8008 C 16.5508 21.1680 17.5352 21.1211 18.0508 21.8008 L 22.4805 27.6133 C 23.0898 28.4336 22.6914 29.4649 21.7305 29.4649 L 18.5664 29.4649 C 18.5664 34.9023 22.4805 39.1445 27.9883 39.1445 C 29.7461 39.1445 31.1758 38.7227 32.5586 37.9727 C 34.1992 37.1523 35.3711 38.1367 35.3711 39.3320 C 35.3711 39.8945 35.0898 40.4336 34.4336 40.8789 C 32.9571 41.8398 30.5664 42.5898 27.9883 42.5898 Z"/>
                  </svg>
                  </div>
                </div>

                <div className="custom-dropdown">
                  <div className="dropdown-content dropdown-content2">
                    <a>
                      <span
                        style={{ opacity: this.state.videoIsStarting ? ".3" : "1" }}
                        onClick={
                          this.state.videoIsStarting ? null : this.handleVideoDuration
                        }
                        id="15"
                        className={
                          this.state.videoDuration === "15"
                            ? "TFilterBtn filterActive"
                            : "TFilterBtn"
                        }
                      >
                        15 min
                      </span>
                    </a>
                    <a>
                      <span
                        style={{ opacity: this.state.videoIsStarting ? ".3" : "1" }}
                        onClick={
                          this.state.videoIsStarting ? null : this.handleVideoDuration
                        }
                        id="30"
                        className={
                          this.state.videoDuration === "30"
                            ? "TFilterBtn filterActive"
                            : "TFilterBtn"
                        }
                      >
                        30 min
                      </span>
                    </a>
                    <a>
                      <span
                        style={{ opacity: this.state.videoIsStarting ? ".3" : "1" }}
                        onClick={
                          this.state.videoIsStarting ? null : this.handleVideoDuration
                        }
                        id="45"
                        className={
                          this.state.videoDuration === "45"
                            ? "TFilterBtn filterActive"
                            : "TFilterBtn"
                        }
                      >
                        45 min
                      </span>
                    </a>
                    <a>
                      <span
                        style={{ opacity: this.state.videoIsStarting ? ".3" : "1" }}
                        onClick={
                          this.state.videoIsStarting ? null : this.handleVideoDuration
                        }
                        id="60"
                        className={
                          this.state.videoDuration === "60"
                            ? "TFilterBtn filterActive"
                            : "TFilterBtn"
                        }
                      >
                        60 min
                      </span>
                    </a>
                  </div>
                  <button class="dropbtn">
                    <svg width="28px" height="28px" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
                        <path d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM3.00683 12C3.00683 16.9668 7.03321 20.9932 12 20.9932C16.9668 20.9932 20.9932 16.9668 20.9932 12C20.9932 7.03321 16.9668 3.00683 12 3.00683C7.03321 3.00683 3.00683 7.03321 3.00683 12Z"/>
                        <path d="M12 5C11.4477 5 11 5.44771 11 6V12.4667C11 12.4667 11 12.7274 11.1267 12.9235C11.2115 13.0898 11.3437 13.2343 11.5174 13.3346L16.1372 16.0019C16.6155 16.278 17.2271 16.1141 17.5032 15.6358C17.7793 15.1575 17.6155 14.5459 17.1372 14.2698L13 11.8812V6C13 5.44772 12.5523 5 12 5Z"/>
                      </svg>
                    <span className="duration-text" style={{marginLeft: '5px'}}>
                      Duration
                    </span>
                    </button>
                </div>

                <div className="my-tooltip">
                  <div className="tooltipText">{this.state.isFullScreen ? 'Exit Fullscreen': 'Enter Fullscreen'}</div>
                  {/* <div className="dropbtn" onClick={ this.state.isFullScreen ? this.closeFullscreen : this.openFullscreen }></div> */}
                  <div className="dropbtn" onClick={ this.toggleCameraVideoPopUp }>
                    {this.state.isCameraOn ?
                        <>
                        <svg width="24px" height="24px" viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg">
                          <g>
                            <path d="M30,60H6A6,6,0,0,0,6,72H24V90a6,6,0,0,0,12,0V66A5.9966,5.9966,0,0,0,30,60Z"/>
                            <path d="M90,60H66a5.9966,5.9966,0,0,0-6,6V90a6,6,0,0,0,12,0V72H90a6,6,0,0,0,0-12Z"/>
                            <path d="M66,36H90a6,6,0,0,0,0-12H72V6A6,6,0,0,0,60,6V30A5.9966,5.9966,0,0,0,66,36Z"/>
                            <path d="M30,0a5.9966,5.9966,0,0,0-6,6V24H6A6,6,0,0,0,6,36H30a5.9966,5.9966,0,0,0,6-6V6A5.9966,5.9966,0,0,0,30,0Z"/>
                          </g>
                        </svg>
                        </> :
                        <>
                          <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 1.5C2.61929 1.5 1.5 2.61929 1.5 4V8.5C1.5 9.05228 1.94772 9.5 2.5 9.5H3.5C4.05228 9.5 4.5 9.05228 4.5 8.5V4.5H8.5C9.05228 4.5 9.5 4.05228 9.5 3.5V2.5C9.5 1.94772 9.05228 1.5 8.5 1.5H4Z" fill="inherit"/>
                            <path d="M20 1.5C21.3807 1.5 22.5 2.61929 22.5 4V8.5C22.5 9.05228 22.0523 9.5 21.5 9.5H20.5C19.9477 9.5 19.5 9.05228 19.5 8.5V4.5H15.5C14.9477 4.5 14.5 4.05228 14.5 3.5V2.5C14.5 1.94772 14.9477 1.5 15.5 1.5H20Z" fill="inherit"/>
                            <path d="M20 22.5C21.3807 22.5 22.5 21.3807 22.5 20V15.5C22.5 14.9477 22.0523 14.5 21.5 14.5H20.5C19.9477 14.5 19.5 14.9477 19.5 15.5V19.5H15.5C14.9477 19.5 14.5 19.9477 14.5 20.5V21.5C14.5 22.0523 14.9477 22.5 15.5 22.5H20Z" fill="inherit"/>
                            <path d="M1.5 20C1.5 21.3807 2.61929 22.5 4 22.5H8.5C9.05228 22.5 9.5 22.0523 9.5 21.5V20.5C9.5 19.9477 9.05228 19.5 8.5 19.5H4.5V15.5C4.5 14.9477 4.05228 14.5 3.5 14.5H2.5C1.94772 14.5 1.5 14.9477 1.5 15.5V20Z" fill="inherit"/>
                          </svg>
                        </>
                      }
                  </div>
                </div>

              </div>
            </div>

        </div>
      </>
    );
  }
}
